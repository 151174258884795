<template>
  <div class="store">
    <header v-if="headerHide">
      <van-nav-bar :left-arrow="true" @click-left="onClickLeft"></van-nav-bar>
    </header>

    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <store-top :shopData="shopData" @serachShop="serachShop"></store-top>
      <goods-list
        :hotProducts="hotProducts"
        :allProducts="allProducts"
      ></goods-list>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>
<script>
import StoreTop from "./components/StoreTop";
import GoodsList from "./components/GoodsList";
import Placeholder from "@/components/common/Placeholder";
import Vue from "vue";
import { List } from "vant";
Vue.use(List);
export default {
  name: "Store",
  components: {
    StoreTop,
    GoodsList,
    Placeholder
  },
  data() {
    return {
      sreachValues: "",
      shopData: {},
      hotProducts: [],
      allProducts: [],
      pageIndex: 1,
      loading: true,
      finished: false,
      isPlaceholder: false,
      shopId: "",
      headerHide: false
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    }
  },
  beforeCreate() {
    this.$commonMethod.setBackgroundColor("#f6f6f6");
  },
  beforeDestroy() {
    document.getElementsByClassName("van-nav-bar")[0].style.display = "block";
  },
  created() {
    const { ShopId, headerHide } = this.$route.query;
    this.shopId = ShopId;
    if (headerHide) {
      this.headerHide = JSON.parse(headerHide);
      if (this.headerHide) {
        setTimeout(() => {
          document.getElementsByClassName("van-nav-bar")[0].style.display =
            "none";
        }, 100);
      }
    }
  },
  mounted() {
    this.loadShop();
  },
  methods: {
    onClickLeft() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.backHome);
    },
    // 首次加载数据
    loadShop() {
      this.$commonMethod.showLoading();
      let param = {};
      param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex,
        PageSize: 6,
        ShopId: this.shopId
      };
      this.$api.shop
        .loadShop(param)
        .then(res => {
          const data = res.data;
          this.shopData = data;
          this.hotProducts = data.HotProducts;
          this.allProducts = data.AllProducts;

          if (
            data.AllProducts == null ||
            data.AllProducts === "" ||
            data.AllProducts === 0
          ) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
  
          this.loading = false;
          this.$commonMethod.hideLoading();
        })
        .catch(error => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    // 加载更多
    onLoad() {
      let param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex + 1,
        PageSize: 6,
        ShopId: this.shopId
      };
      this.$api.shop
        .loadShopProductByPage(param)
        .then(res => {
          const data = res.data;
          if (data == null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.allProducts = this.allProducts.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    // 搜索方法
    serachShop(sreachValues) {
      this.$commonMethod.showLoading();
      this.sreachValues = sreachValues;
      this.pageIndex = 1;
      this.finished = false;
      let param = {};
      param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex,
        PageSize: 6,
        ShopId: this.shopId
      };
      this.$api.shop
        .loadShop(param)
        .then(res => {
          const data = res.data;
          this.allProducts = data.AllProducts;
          this.hotProducts = data.HotProducts;;
          this.$commonMethod.hideLoading();
        })
        .catch(error => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    }
  }
};
</script>
<style scoped>
html,
body {
  height: 100%;
}
body {
  overflow: hidden;
}
header {
  height: auto;
  background: rgb(102, 102, 102);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}

.myStore {
  min-height: 100vh;
}
</style>
